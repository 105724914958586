import './App.css';
import portrait from './wrigley.jpg'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={portrait} className="App-logo" alt="William Wrigley, Jr" />
        <h3>
          A few yes men may be born, but mostly they are made. Fear is a great breeder of them.
        </h3>
        <p>
          - William Wrigley, Jr
        </p>
        <br/><br/><br/><br/>
        <button onClick={() => window.location = 'mailto:fosteringculture@gmail.com'}>This domain is for sale! Contact the webmaster</button>
      </header>
    </div>
  );
}

export default App;
